import {
  cloneDeepObject,
  convertComponentToJSON,
  getCurrentValueFromControl,
  isObject,
  useControlSelectByCondition,
} from '@gem/common';
import type { SettingUIControl } from '@gem/control-v2';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import useSettingSideBarStore from './useSettingSideBarStore';

const useControlData = (data: { controlData: ComputedRef<SettingUIControl> }) => {
  const { controlData } = data;
  const settingSideBarStore = useSettingSideBarStore();
  const controlComponents = computed(() => settingSideBarStore.getControlComponents);
  const editingPageType = computed(() => settingSideBarStore.getEditingPageType);
  const activeScreenId = computed(() => settingSideBarStore.currentDevice);
  const pickedDynamicProduct = computed(() => settingSideBarStore.pickedDynamicProduct);
  const pickedDynamicCollection = computed(() => settingSideBarStore.pickedDynamicCollection);
  const editingComponentUID = computed(() => settingSideBarStore.editingComponentUid);
  const themePageStatus = computed(() => settingSideBarStore.themePageStatus);
  const searchKeyword = computed(() => settingSideBarStore.searchKeyword);

  const controlSetting = computed(() => controlData.value.mapTo?.control);
  const section = computed(() => settingSideBarStore.section);
  const elementData = computed(() => settingSideBarStore.elementData);
  const controlType = computed(() => controlSetting?.value?.type);
  const sectionComponents = computed(() => {
    if (section?.value?.component) {
      const stringComponent = section.value.component;
      return convertComponentToJSON(stringComponent);
    }
    return null;
  });

  const currentComponentUid = computed(() => {
    return controlSetting?.value?.componentUid ?? editingComponentUID.value;
  });

  const getControlNameByType = computed(() => {
    if (controlType.value) {
      return controlComponents.value[controlType.value];
    }
    return '';
  });

  const componentStyles = computed(() => {
    return elementData.value?.styles;
  });

  const getRenderProps = computed(() => {
    if (!controlSetting.value) return;
    const control = cloneDeepObject(controlSetting.value);
    const appendProps: Record<string, any> = {
      componentTag: elementData.value?.tag,
      componentLabel: elementData.value?.label,
      componentUid: currentComponentUid.value,
      sectionId: section.value?.cid,
      controlType: control.type,
      hasDevices: !!(control.devices && isObject(control.devices)),
      componentStyles: componentStyles.value,
      themePageStatus: themePageStatus.value,
      searchKeyword: searchKeyword.value,
    };

    // Merge control attrs to props
    const ignoreKeys = ['type', 'controls', 'links', 'hide', 'simple', 'allowReset', 'state', 'show'];
    Object.entries(control).forEach(([key, value]) => {
      if (ignoreKeys.includes(key)) return;
      appendProps[key] = value;
    });

    control.default = getCurrentValueFromControl({
      control: control,
      screenId: activeScreenId.value,
    });
    appendProps.value = control.default;

    if (['visibility', 'layout', 'grid'].includes(control.type)) {
      appendProps.devices = control.devices;
    }

    modifyValueByState(appendProps, control.default);
    modifyValueProductSetting(appendProps);
    modifyValueByOptions(appendProps);

    return appendProps;
  });

  const modifyValueByState = (sourceProps: Record<string, any>, defaultValue: any) => {
    if (controlData?.value?.mapTo?.state) {
      let stateVal = defaultValue?.[controlData?.value.mapTo?.state];
      if (stateVal == undefined) {
        stateVal = defaultValue?.normal;
      }
      sourceProps.value = stateVal;
    }
  };

  const modifyValueByOptions = (sourceProps: Record<string, any>) => {
    if (sourceProps.options?.length) {
      const { newOptions, isChangeOption } = useControlSelectByCondition(
        sourceProps.options,
        sectionComponents.value,
        currentComponentUid.value,
      );
      sourceProps.options = newOptions;
      if (sourceProps.value && isChangeOption) {
        const existValue = sourceProps.options.find((item: any) => item.value == sourceProps.value);
        if (!existValue) {
          sourceProps.value = sourceProps.options?.[0]?.value;
          sourceProps.triggerControlChange = true;
        }
      }
    }
  };

  const modifyValueProductSetting = (sourceProps: Record<string, any>) => {
    const pageType = editingPageType.value;
    if (
      pageType == 'GP_PRODUCT' &&
      sourceProps.componentTag == 'Product' &&
      sourceProps.id == 'productSetting' &&
      sourceProps.default?.productId == 'latest' &&
      sourceProps.default?.productStatus == 'dynamic' &&
      pickedDynamicProduct.value?.productId
    ) {
      sourceProps.value = pickedDynamicProduct;
      sourceProps.value.isUpdatedProduct = true;
    } else if (
      pageType == 'GP_COLLECTION' &&
      sourceProps.componentTag == 'ProductList' &&
      sourceProps.id == 'collectionId'
    ) {
      sourceProps.value = pickedDynamicCollection.value?.collectionId;
    }
  };

  return {
    controlName: getControlNameByType,
    controlProps: getRenderProps,
  };
};

export default useControlData;
