<script setup lang="ts">
import type { ControlChangeValueType, SettingUIControl } from '@gem/control-v2';
import Control from './Control.vue';
import ComboSetting from './ComboSetting.vue';
import { computed } from 'vue';
import TabSettings from './TabSettings.vue';
type ControlSettingsProps = {
  controls: SettingUIControl[];
  hiddenDevice?: boolean;
};
const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();

const props = defineProps<ControlSettingsProps>();

const controlsDisplay = computed(() => props.controls?.filter((item) => !item.hide));

const handleControlChange = (data: ControlChangeValueType) => {
  emit('controlChange', data);
};

const handleControlOnChange = (data: ControlChangeValueType) => {
  emit('controlOnChange', data);
};
</script>

<template>
  <div v-if="controls.length" class="flex flex-col gap-12">
    <template v-for="(control, index) in controlsDisplay" :key="`${index}`">
      <Control
        v-if="!control.type || control.type === 'control'"
        :data="control"
        :hidden-device="hiddenDevice"
        data-test="control-setting"
        @control-change="handleControlChange"
        @control-on-change="handleControlOnChange" />
      <ComboSetting
        v-if="control.type === 'compo'"
        :data="control"
        @control-change="handleControlChange"
        @control-on-change="handleControlOnChange" />
      <TabSettings
        v-if="control.type === 'tab'"
        :data="control.tabs"
        @control-change="handleControlChange"
        @control-on-change="handleControlOnChange" />
    </template>
  </div>
</template>
