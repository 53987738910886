<script setup lang="ts">
import type { LabelWithLang, SettingUIHelpType } from '@gem/control-v2';
import Tip from './Tip.vue';
import type { ScreenType } from '@gem/common';
import DeviceSelectPopup from '../controls/device-select/DeviceSelectPopup.vue';
type SettingTitleProps = {
  label?: LabelWithLang;
  variant?: 'primary' | 'secondary';
  help?: SettingUIHelpType;
  showDevices?: boolean;
  currentDevice?: ScreenType;
};

withDefaults(defineProps<SettingTitleProps>(), {
  variant: 'primary',
});

const emit = defineEmits<{
  (e: 'changeScreen', screenId: ScreenType): void;
}>();

const setActiveDevice = (screenId: ScreenType) => {
  emit('changeScreen', screenId);
};
</script>

<template>
  <div
    v-if="label"
    class="text-12 flex h-[32px] items-center gap-4"
    :class="{
      'text-light-100 font-medium': variant === 'primary',
      'text-text-dark-300': variant === 'secondary',
    }">
    {{ label.en }}
    <DeviceSelectPopup v-if="showDevices" :current-screen="currentDevice" @change-screen="setActiveDevice" />
    <div v-if="help" class="flex items-center">
      <Tip v-if="help" :content="help.content" :button="help.button" :media="help.media" />
    </div>
  </div>
</template>
