<script setup lang="ts">
import type { ControlChangeValueType, SettingUIControl } from '@gem/control-v2';
import { GTooltip } from '@gem/uikit';
import useControlData from '../hooks/useControlData';
import SettingTitle from './SettingTitle.vue';
import ControlLocked from './ControlLocked.vue';
import type { ActionOptions, Plan, ScreenType, SwatchesOptionType } from '@gem/common';
import { cn } from '@gem/common';
import { computed } from 'vue';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';

type ControlSettingProps = {
  data: SettingUIControl;
  hiddenDevice?: boolean;
};

const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();

const props = defineProps<ControlSettingProps>();

const settingSideBarStore = useSettingSideBarStore();
const currentDevice = computed(() => settingSideBarStore.currentDevice);
const shopPlan = computed(() => settingSideBarStore.shopPlan);

const controlData = computed(() => props.data);

const hasDevices = computed(() => !!props.data?.mapTo?.control.devices);

const isFullWidth = computed(() =>
  props.data?.options?.fullWidth === undefined ? true : props.data?.options?.fullWidth,
);

const isLockedControl = computed(() => {
  const lockedOnPlans = props.data.options?.lockedOnPlans;
  if (lockedOnPlans && shopPlan.value) {
    return lockedOnPlans.includes(shopPlan.value as Plan);
  }

  return false;
});

const nearestSupportedPlan = computed(() => {
  return props.data.options?.nearestSupportedPlan;
});

const CONTROL_AUTO_WIDTH = ['toggle', 'colorpicker'];
const isControlAutoWidth = computed(() => CONTROL_AUTO_WIDTH.includes(props.data.mapTo?.control.type || ''));

const { controlName, controlProps } = useControlData({ controlData: controlData });

const modifyValue = (
  controlId: string,
  val?: any,
  screen?: ScreenType,
  options?: ActionOptions,
  customizeOldData?: string,
) => {
  return {
    val,
    controlId,
    screen,
    options,
    customizeOldData,
    state: props.data.setting?.state,
    default: controlProps?.value?.default,
    controlType: props.data.mapTo?.control.type,
    controlChangeTrigger: props.data.controlChangeTrigger,
    tabID: controlProps?.value?.tabId,
    devices: controlProps?.value?.devices,
    linkWithSetting: controlProps?.value?.linkWithSetting,
  };
};

const handleControlChange = (
  controlId: string,
  val?: any,
  screen?: ScreenType,
  options?: ActionOptions,
  customizeOldData?: string,
) => {
  emit('controlChange', modifyValue(controlId, val, screen, options, customizeOldData));
};

const handleControlOnChange = (
  controlId: string,
  val?: any,
  screen?: ScreenType,
  options?: ActionOptions,
  customizeOldData?: string,
) => {
  emit('controlOnChange', modifyValue(controlId, val, screen, options, customizeOldData));
};

const handleChangeActiveDevice = (device: ScreenType) => {
  settingSideBarStore.setCurrentDevice(device);
};

const openSwatchesManagement = (optionType: SwatchesOptionType, optionTitle: string) => {
  settingSideBarStore.setSwatchesManagerData({
    openManageSwatches: true,
    optionType: optionType ?? '',
    optionTitle: optionTitle ?? '',
  });
};
</script>

<template>
  <div
    v-if="!data.hide"
    class="relative flex"
    :class="
      cn([
        data.layout === 'vertical' ? 'flex-col justify-start gap-8' : 'items-center justify-between gap-4',
        !isFullWidth ? 'pl-24' : '',
        data.disabled ? 'opacity-30' : '',
      ])
    "
    :style="data.options?.styles">
    <div
      v-if="data.disabled"
      class="hover:bg-underlay-red-100 rounded-12 absolute top-0 left-0 z-[100] h-full w-full cursor-not-allowed">
      <GTooltip
        placement="top"
        class="!block h-full w-full"
        content-class="max-w-[200px] whitespace-normal"
        :is-teleport="true">
        <template #content>
          {{ data?.options?.disableMessage || 'Settings cannot be used at this time' }}
        </template>
      </GTooltip>
    </div>
    <div v-if="isLockedControl && data.label && nearestSupportedPlan">
      <ControlLocked
        :label="data.label?.en || ''"
        :nearest-supported-plan="nearestSupportedPlan"
        :control="data.mapTo?.control.type">
        <template #label>
          <SettingTitle :label="data.label" :variant="data.options?.labelVariant || 'secondary'" />
        </template>
      </ControlLocked>
    </div>
    <template v-else>
      <SettingTitle
        v-if="!data?.options?.hideLabel && !data.options?.labelInsideControl"
        :label="data.label"
        :help="data.help"
        :variant="data.options?.labelVariant || 'secondary'"
        :show-devices="hasDevices && !hiddenDevice && !data.options?.hiddenSwitchDeviceIcon"
        :current-device="currentDevice"
        @change-screen="handleChangeActiveDevice" />
      <div
        :class="
          cn([
            'flex-shrink-0',
            data.layout === 'vertical' ? 'w-full' : 'w-full max-w-[140px]',
            isControlAutoWidth ? '!w-auto' : '',
          ])
        ">
        <component
          :is="controlName"
          v-bind="controlProps"
          @open-swatches-modal="openSwatchesManagement"
          @control-change="handleControlChange"
          @control-on-change="handleControlOnChange">
          <template v-if="data.options?.labelInsideControl" #label>
            <SettingTitle
              :label="data.label"
              :help="data.help"
              :variant="data.options?.labelVariant || 'secondary'"
              :show-devices="hasDevices && !hiddenDevice"
              :current-device="currentDevice"
              @change-screen="handleChangeActiveDevice" />
          </template>
        </component>
      </div>
    </template>
  </div>
</template>
