<script setup lang="ts">
import { computed } from 'vue';
import useSettingSideBarStore from '../../hooks/useSettingSideBarStore';
import SettingTitle from '../../settingUI/SettingTitle.vue';
import { RangeSlide } from '@gem/control';

const props = defineProps<{
  gap?: string;
}>();
const emit = defineEmits<{
  (e: 'controlOnChange', value?: string): void;
  (e: 'controlChange', value?: string): void;
}>();
const sidebarStore = useSettingSideBarStore();
const globalStyles = computed(() => sidebarStore.getGlobalStyle);
const activeScreenId = computed(() => sidebarStore.currentDevice);
const spacingOptions = computed(() => {
  const options = globalStyles?.value
    ? Object.entries(globalStyles.value.spacing).map(([spacingKey, value]) => {
        return {
          id: spacingKey,
          name: spacingKey.toUpperCase(),
          value: activeScreenId.value in value ? value[activeScreenId.value] : value.desktop,
        };
      })
    : [];

  return options;
});

const currentValue = computed(() => {
  const v = spacingOptions.value.find((item) => props.gap === `var(--g-s-${item.id})`);
  return v ? v?.value : props.gap;
});

const controlChangeHandler = (id?: string, value?: string) => {
  emit('controlChange', value);
};

const controlOnChangeHandler = (id?: string, value?: string) => {
  emit('controlOnChange', value);
};
</script>
<template>
  <div class="relative flex w-full flex-col justify-center gap-4">
    <SettingTitle :label="{ en: 'Gap' }" variant="secondary" />
    <RangeSlide
      :value="currentValue"
      :units="['px']"
      :min="0"
      :max="200"
      input-width="50%"
      @control-change="controlChangeHandler"
      @control-on-change="controlOnChangeHandler" />
  </div>
</template>
