<script setup lang="ts">
import type { ControlChangeValueType, SettingUIMoreSetting } from '@gem/control-v2';
import { GButton, GPopover } from '@gem/uikit-v2';
import { ref } from 'vue';
import SettingTitle from './SettingTitle.vue';

type MoreSettingsProps = {
  data?: SettingUIMoreSetting;
};

defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();

defineProps<MoreSettingsProps>();

const isOpen = ref(false);
const isCollapseAction = true;
const collapsed = ref(true);
const handleCollapsed = () => {
  collapsed.value = !collapsed.value;
};
</script>

<template>
  <template v-if="isCollapseAction">
    <div class="flex flex-col gap-16">
      <slot v-if="!collapsed"></slot>
      <GButton
        type="secondary"
        size="medium"
        button-width="full"
        icon-view-box="0 0 16 16"
        :icon-after="collapsed ? 'polaris-chevron-down' : 'polaris-chevron-up'"
        @click="handleCollapsed()">
        {{ collapsed ? 'Show more' : 'Show less' }}
      </GButton>
    </div>
  </template>
  <template v-else>
    <GPopover
      v-if="data"
      :no-padding="true"
      :placement-offset="20"
      closeable
      cls="bg-dark-500"
      button-class="!w-full"
      wrap-content-class="!w-full"
      wrapper-class="transform -translate-y-[12px]"
      :enable-flip="false"
      placement="right-start">
      <GButton
        :active="isOpen"
        type="secondary"
        size="medium"
        icon-after="polaris-chevron-right"
        icon-view-box="0 0 16 16"
        button-width="full">
        {{ data.labelAction?.en }}
      </GButton>
      <template #content="{ close }">
        <div class="w-[280px]">
          <div class="border-dark-300 flex items-center justify-between border-b py-10 pl-16 pr-10">
            <SettingTitle :label="data.label" />
            <GButton only-icon="polaris-x" type="ghost" size="semi-medium" @click="close" />
          </div>
          <div class="flex flex-col gap-16 p-16">
            <slot></slot>
          </div>
        </div>
      </template>
    </GPopover>
  </template>
</template>
