<script setup lang="ts">
import type { ControlChangeValueType, SettingUIControl } from '@gem/control-v2';
import { GButton, GPopover, GInput } from '@gem/uikit-v2';
import SettingTitle from './SettingTitle.vue';
import ControlSettings from './ControlSettings.vue';
import { computed, ref } from 'vue';
import { cn } from '@gem/common';

type ComboSettingProps = {
  data: SettingUIControl;
};

const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();

const props = defineProps<ComboSettingProps>();

const controlIDGetContent = computed(() => props.data.getValueFromSettingID);

const contentDisplay = ref(getContentByControlID(props.data.getValueFromSettingID) || props.data.placeholder);
const comboIcon = ref(props.data.iconName);

function getContentByControlID(controlID?: string) {
  const control = props.data.controls?.find((item) => item.mapTo?.control?.id === controlID)?.mapTo?.control;
  return control?.default;
}

const handelControlChange = (data: ControlChangeValueType) => {
  if (data.controlId === controlIDGetContent.value) {
    contentDisplay.value = data.val;
  }
  emit('controlChange', data);
};

const handelControlOnChange = (data: ControlChangeValueType) => {
  emit('controlOnChange', data);
};
</script>

<template>
  <div class="flex justify-between gap-8" :class="cn([!data.options?.fullWidth ? 'pl-24' : ''])">
    <SettingTitle :label="data?.label" variant="secondary" />
    <div class="w-[140px] flex-shrink-0">
      <GPopover
        :no-padding="true"
        :placement-offset="20"
        closeable
        cls="bg-dark-500"
        button-class="!w-full"
        wrap-content-class="!w-full"
        placement="right-start">
        <GInput :readonly="true" :active="true" :prefix-icon="comboIcon" :value="contentDisplay" :light-mode="false" />
        <template #content="{ close }">
          <div class="w-[280px]">
            <div class="border-dark-300 flex items-center justify-between border-b py-10 pl-16 pr-10">
              <SettingTitle :label="data.label" :help="data.help" />
              <GButton only-icon="polaris-x" type="ghost" size="semi-medium" @click="close" />
            </div>
            <div class="p-16">
              <ControlSettings
                v-if="data.controls?.length"
                :controls="data.controls"
                @control-change="handelControlChange"
                @control-on-change="handelControlOnChange" />
            </div>
          </div>
        </template>
      </GPopover>
    </div>
  </div>
</template>
