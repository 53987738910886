<script lang="ts" setup>
import DeviceRecommend from './DeviceRecommend.vue';
import ColumnOrder from './ColumnOrder.vue';
import MoreSettings from '../../settingUI/MoreSettings.vue';
import ColumnWidth from './ColumnWidth.vue';
import { GridArrange } from '@gem/control-v2';
import { useRowLayoutSettingsUpdate } from './hooks/useRowLayoutSettingsUpdate';
import type { ActionSettingInput, ChildItem, GridSettingInput, LayoutValue } from './types';
import type { ScreenType } from '@gem/common';
import DeviceProductRecommend from './DeviceProductRecommend.vue';
import ColumnGap from './ColumnGap.vue';

export type RowLayoutProps = {
  id: string;
  controlType: string;
  currentScreen?: ScreenType;
  value?: LayoutValue;
  childrens?: ChildItem[];
  maxCol?: number;
  isProductLayout?: boolean;
  isSupportResponsiveOrder?: boolean;
  devices?: {
    desktop?: {
      default?: LayoutValue;
    };
    tablet?: {
      default?: LayoutValue;
    };
    mobile?: {
      default?: LayoutValue;
    };
  };
};
const emit = defineEmits<{
  (e: 'control-change', id: RowLayoutProps['id'], value: RowLayoutProps['value'] | undefined): void;
  (e: 'control-on-change', id: RowLayoutProps['id'], value: RowLayoutProps['value'] | undefined): void;
  (e: 'control-change-children', input: ActionSettingInput[]): void;
  (e: 'control-change-layout', input: GridSettingInput[]): void;
  (e: 'remove-item', uid: string): void;
  (e: 'copy-item', uid: string): void;
  (e: 'add-item'): void;
  (e: 'changeScreen', screenId: ScreenType): void;
}>();
const props = withDefaults(defineProps<RowLayoutProps>(), {
  maxCol: 6,
  currentScreen: 'desktop',
  isSupportResponsiveOrder: true,
});

const {
  maxColumn,
  displayValue,
  orderItems,
  isDisplayColumnGapSetting,
  handleChangeLayout,
  handleChangeDisplay,
  handleChangeCol,
  handleOnChangeCol,
  onReOrder,
  handleCopyItem,
  handleRemoveItem,
  handleChangeGap,
} = useRowLayoutSettingsUpdate(props, emit);
</script>

<template>
  <div class="w-full">
    <slot name="label"></slot>

    <div class="relative flex w-full flex-col gap-16">
      <DeviceProductRecommend v-if="isProductLayout" :value="value" :max-col="2" @change="handleChangeLayout" />
      <DeviceRecommend
        v-else
        :value="value"
        :device="currentScreen"
        :max-col="maxColumn"
        @change="handleChangeLayout" />
      <MoreSettings :data="{ label: { en: 'Column settings' }, labelAction: { en: 'Layout more settings' } }">
        <div class="flex flex-col gap-8">
          <ColumnWidth :display-type="value?.display" @on-change-display-type="handleChangeDisplay" />
          <div v-if="displayValue === 'fill'" class="mt-8">
            <GridArrange :cols="value?.cols" @control-change="handleChangeCol" @control-on-change="handleOnChangeCol" />
          </div>
        </div>
        <ColumnOrder
          :childrens="childrens"
          :order-items="orderItems"
          :current-screen="currentScreen"
          :is-support-responsive-order="isSupportResponsiveOrder"
          @copy-item="handleCopyItem"
          @remove-item="handleRemoveItem"
          @on-re-order="onReOrder" />
        <ColumnGap
          v-if="isDisplayColumnGapSetting"
          :gap="value?.gap"
          @control-change="(value) => handleChangeGap('change', value)"
          @control-on-change="(value) => handleChangeGap('onChange', value)" />
      </MoreSettings>
    </div>
    <slot name="info"></slot>
  </div>
</template>
