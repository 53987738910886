<script setup lang="ts">
import { computed, watch, type Component } from 'vue';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';
import ElementSettingsGroup from './ElementSettingsGroup.vue';
import type { ControlChangeValueType, SettingUIGroup } from '@gem/control-v2';
import type {
  DynamicCollectionType,
  GlobalStyleResponsiveConfig,
  PickedProductType,
  ScreenType,
  Section,
  SwatchesManagerModelData,
  ThemePageType,
  Component as ElementComponent,
} from '@gem/common';
import { useFlattenControlUI } from './hooks/useFlattenControlUI';

const props = defineProps<{
  settings: SettingUIGroup[];
  section: Section | undefined;
  elementData: ElementComponent | undefined;
  controlComponents: Record<string, Component>;
  editingComponentUid: string | null;
  currentDevice: ScreenType;
  searchKeyword: string;
  editingPageType: '' | ThemePageType | 'THEME_SECTION';
  themePageStatus: string;
  shopPlan?: string;
  pickedDynamicProduct?: PickedProductType | null;
  pickedDynamicCollection?: DynamicCollectionType;
  globalStyles?: GlobalStyleResponsiveConfig | undefined;
  shopifyDomain?: string;
}>();

const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
  (event: 'changeDevice', screenId: ScreenType): void;
  (event: 'goToPricing', value?: string): void;
  (event: 'openSwatchesManager', value?: SwatchesManagerModelData): void;
}>();
const settingSideBarStore = useSettingSideBarStore();

const setSideBarStoreValue = () => {
  settingSideBarStore.setControlComponents(props.controlComponents);
  settingSideBarStore.setEditingComponentUid(props.editingComponentUid);
  settingSideBarStore.setCurrentDevice(props.currentDevice);
  settingSideBarStore.setEditingPageType(props.editingPageType);
  settingSideBarStore.setPickedDynamicProduct(props?.pickedDynamicProduct);
  settingSideBarStore.setPickedDynamicCollection(props?.pickedDynamicCollection);
  settingSideBarStore.setThemePageStatus(props?.themePageStatus);
  settingSideBarStore.setGlobalStyle(props?.globalStyles);
  settingSideBarStore.setShopPlan(props?.shopPlan);
  settingSideBarStore.setShopifyDomain(props?.shopifyDomain);
  settingSideBarStore.setElementData(props?.elementData);
  settingSideBarStore.setSection(props?.section);
};
setSideBarStoreValue();
const { groupSettingsFlatten } = useFlattenControlUI();
const displaySettingGroups = computed(() => {
  return props.settings.filter((item) => {
    const groupControlsDisplay = groupSettingsFlatten(item).filter((item) => !item.hide);
    return !item.hide && groupControlsDisplay.length;
  });
});

const goToPricing = computed(() => settingSideBarStore.goToPricing);
const currentDevice = computed(() => settingSideBarStore.currentDevice);
const swatchesManagerData = computed(() => settingSideBarStore.swatchesManagerData);

const handleControlChange = (data: ControlChangeValueType) => {
  emit('controlChange', data);
};

const handelControlOnChange = (data: ControlChangeValueType) => {
  emit('controlOnChange', data);
};

watch(
  () => props,
  () => {
    setSideBarStoreValue();
  },
  { deep: true },
);

watch(goToPricing, (newValue) => {
  if (newValue) {
    emit('goToPricing', newValue);
    settingSideBarStore.setGoToPricing(undefined);
  }
});

watch(currentDevice, (newValue, oldValue) => {
  if (newValue != oldValue) {
    emit('changeDevice', newValue);
  }
});

watch(
  swatchesManagerData,
  (newValue) => {
    if (!newValue) return;
    emit('openSwatchesManager', newValue);
    settingSideBarStore.setSwatchesManagerData(undefined);
  },
  { deep: true },
);
</script>

<template>
  {{ swatchesManagerData }}
  <div id="#setting-panel" class="gemx-controls bg-dark-500 pb-[50px]">
    <div class="flex flex-col">
      <template v-for="(group, index) in displaySettingGroups" :key="`${index}`">
        <ElementSettingsGroup
          :data="group"
          :no-border="index === displaySettingGroups.length - 1"
          @control-change="handleControlChange"
          @control-on-change="handelControlOnChange" />
      </template>
    </div>
  </div>
</template>
