<script lang="ts" setup>
import { computed } from 'vue';

type LayoutValue = {
  display?: string;
  cols?: number[];
};

withDefaults(
  defineProps<{
    value?: LayoutValue;
    maxCol?: number;
  }>(),
  {
    maxCol: 1,
  },
);

const emit = defineEmits<{
  (e: 'change', value: number): void;
}>();

const layouts = computed(() => {
  return [
    {
      col: 2,
      icon: `product-two-layout`,
      iconHover: `product-two-layout-hover`,
    },
    {
      col: 1,
      icon: 'product-one-layout',
      iconHover: 'product-one-layout-hover',
    },
  ];
});

const handleChangeLayout = (cols: number) => {
  emit('change', cols);
};
</script>

<template>
  <slot></slot>
  <div class="grid w-full grid-cols-2 gap-8">
    <div
      v-for="item in layouts"
      :key="item.col"
      class="group/layout grid max-w-full cursor-pointer gap-[2px] rounded-xl border border-transparent"
      :class="{
        'border-primary-300': item.col == value?.cols?.length,
      }"
      @click="handleChangeLayout(item.col)">
      <div class="bg-dark-400 flex h-[80px] items-center justify-center overflow-hidden rounded-xl">
        <g-base-icon
          :name="item.icon"
          width="88px"
          height="48px"
          color="red"
          view-box="0 0 88 48"
          class="group-hover/layout:hidden"></g-base-icon>
        <g-base-icon
          :name="item.iconHover"
          width="88px"
          height="48px"
          color="red"
          view-box="0 0 88 48"
          class="hidden group-hover/layout:flex"></g-base-icon>
      </div>
    </div>
  </div>
</template>
