import type { ActionSettingInput, DisplayType, GridSettingInput, LayoutValue, RowLayoutProps } from '@gem/control-v2';
import { computed } from 'vue';
import { getResponsiveValueByScreen } from '@gem/common';
import useSettingSideBarStore from '../../../hooks/useSettingSideBarStore';

export const useRowLayoutSettingsUpdate = (props: RowLayoutProps, emit: any) => {
  const sideBarStore = useSettingSideBarStore();
  const items = computed(() => {
    const currentScreen = props.isSupportResponsiveOrder ? props.currentScreen : 'desktop';
    return [...(props.childrens ?? [])].sort((a, b) => {
      const order1 = getResponsiveValueByScreen(a.styles?.order, currentScreen) ?? 0;
      const order2 = getResponsiveValueByScreen(b.styles?.order, currentScreen) ?? 0;
      if (order1 !== order2) {
        return Number(order1) - Number(order2);
      }
      return 0;
    });
  });

  const orderItems = computed(() => {
    return items.value.map((v) => ({
      id: v.uid,
      title: 'Column',
    }));
  });

  const maxColumn = computed(() => {
    return props?.currentScreen === 'desktop' ? props.maxCol : props.childrens?.length ?? 6;
  });

  const displayValue = computed(() => {
    return props.value?.display ?? 'fill';
  });

  const isDisplayColumnGapSetting = computed(() => {
    const cols = props.value?.cols?.length ?? 0;
    const isPostPurchase = sideBarStore.editingPageType == 'POST_PURCHASE';
    return cols > 1 && !isPostPurchase;
  });

  const onReOrder = (data: { id?: string; title?: string }[]) => {
    const input: ActionSettingInput[] = (data.filter((i) => !!i.id) as { id: string; title?: string }[]).map(
      (item, index) => {
        return {
          groupType: 'style',
          componentUid: item.id,
          controlType: 'input',
          controlId: 'order',
          newValue: index,
          screenId: props.currentScreen || 'desktop',
          hasDevices: true,
        };
      },
    );
    emit('control-change-children', input);
  };

  const handleChangeDisplay = (type: DisplayType) => {
    if (displayValue.value === type) return;
    emit('control-change', props.id, {
      ...props.value,
      display: type,
    });
  };

  const handleChangeCol = (cols: number[]) => {
    emit('control-change', props.id, {
      ...props.value,
      cols,
    });
  };

  const handleOnChangeCol = (cols: number[]) => {
    emit('control-on-change', props.id, {
      ...props.value,
      cols,
    });
  };

  const handleChangeLayout = (totalCol: number) => {
    if (props.value?.cols?.length === totalCol || !totalCol) return;
    if (props?.currentScreen === 'desktop') {
      const input: GridSettingInput[] = [
        {
          newValue: {
            ...props.value,
            cols: Array.from({ length: totalCol }, () => 12 / totalCol),
          },
          screenId: 'desktop',
        },
        { newValue: undefined, screenId: 'tablet' },
      ];
      emit('control-change-layout', input);
    } else {
      emit('control-change', props.id, {
        ...props.value,
        cols: Array.from({ length: totalCol }, () => 12 / totalCol),
        display: props.value?.display ?? 'fill',
      });
    }
  };

  const handleChangeGap = (type: 'onChange' | 'change', value?: string) => {
    const newData: LayoutValue = {
      ...props.value,
      gap: value,
    };
    if (type === 'change') emit('control-change', props.id, newData);
    else emit('control-on-change', props.id, newData);
  };

  const handleRemoveItem = (uid: string) => {
    emit('remove-item', uid);
  };

  const handleCopyItem = (uid: string) => {
    emit('copy-item', uid);
  };

  return {
    maxColumn,
    displayValue,
    orderItems,
    isDisplayColumnGapSetting,
    handleChangeLayout,
    onReOrder,
    handleChangeDisplay,
    handleChangeCol,
    handleOnChangeCol,
    handleCopyItem,
    handleRemoveItem,
    handleChangeGap,
  };
};
