<script setup lang="ts">
import { computed } from 'vue';
import type { DisplayType } from './types';
import SettingTitle from '../../settingUI/SettingTitle.vue';

type RowLayoutColumnWidthProps = {
  displayType?: DisplayType;
};

const props = defineProps<RowLayoutColumnWidthProps>();

const emit = defineEmits<(e: 'onChangeDisplayType', displayType: DisplayType) => void>();

const displayValue = computed(() => {
  return props.displayType ?? 'fill';
});
const displayOptions: {
  label: string;
  value: DisplayType;
}[] = [
  {
    label: 'Fit to content',
    value: 'fit',
  },
  {
    label: 'By ratio',
    value: 'fill',
  },
];

const handleChangeDisplay = (type: DisplayType) => {
  if (displayValue.value === type) return;
  emit('onChangeDisplayType', type);
};
</script>

<template>
  <div>
    <SettingTitle :label="{ en: 'Column width' }" variant="secondary" />
    <div class="relative flex w-full flex-col justify-center gap-4">
      <div
        v-for="item in displayOptions"
        :key="item.value"
        class="text-12 group/radio text-light-450 font-regular rounded-medium flex h-28 cursor-pointer select-none items-center gap-8 text-center"
        @click.stop="handleChangeDisplay(item.value)">
        <div
          class="flex h-16 w-16 items-center justify-center rounded-full border-[1.25px]"
          :class="{
            'border-primary-300 before:bg-primary-300 before:h-8 before:w-8 before:rounded-full':
              displayValue === item.value,
            'border-text-dark-300 ': displayValue !== item.value,
          }"></div>
        <div
          class="group-hover/radio:bg-overlay-white pointer-events-none absolute left-[-6px] h-28 w-28 rounded-full"></div>
        <span>{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>
